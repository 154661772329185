body {
   font-family: Open Sans,Helvetica Neue,sans-serif;
   font-size: 14px;
   color: #333;
   margin: 0;
   padding: 0;
   min-height: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
