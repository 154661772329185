

html {
  font-size: 15px;
}

.root-child {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout-topbar {
  background-color: #20272a;
}
.mainpage-wrapper {
  margin-right: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 50px;
  flex: 1;
}

.topnav {
  overflow: hidden;
}

.topnav a {
  float: right;
  text-decoration: none;
  color: #fff;
  line-height: 70px;
  display: inline-block;
  min-width: 120px;
  text-align: center;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;

}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.active {
  background-color: #4CAF50;
  color: white;
}

.topnav .icon {
  display: none;
  font-size: 2em;
}

@media screen and (max-width: 800px) {
  .topnav a {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
    padding-left: 0px;
    text-align: center;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
    padding-left: 20px;
  }
}

.logo {
  max-height: 45px;
  margin: 10px;
}

.layout-footer {
    background-color: #20272a;
    height: 70px;
    color: white;
    text-align: center;
    /* margin-bottom: -0.5em; */
    margin-top: auto;
}

.p-datatable .p-datatable-thead > tr > th {
  vertical-align: top;
}
.p-datatable .p-datatable-tbody>tr>td {
    overflow-x: auto;
}

.p-highlight-pink {
    background-color: #fcd3d3 !important;
}

.p-datatable-responsive .p-datatable-tbody tr>th .p-column-title {
  display: block;
}
/* this is to hide the column header for the delivery row expander icon
when in phone mode, otherwise it is too far right with the other 'values'
*/
@media screen and (max-width: 40em) {
  td.expander .p-column-title {
    display: none !important;
    background-color: green;
  }
}

.table-title {
  display: flex;
  padding: 15px 0px 10px 0px;
  align-items: center;
}

.table-title label {
  font-size: 1.5em;
}

.dialog {
  width: 90vw;
  max-width: 500px;
}

.dialog-field {
  margin-top: 2em;
}

button.table-button {
  margin-right: 0.25em;
}
.tools-column {
  text-align: center;
  width: 120px;
}

/* table filters */
.p-column-filter {
  width: 100%;
}

.space-right {
  margin-right: .5rem;
}

.right {
  text-align: right;
}

/*
make tables responsive
*/

.p-column-title-resp {
  display: none;
}
@media screen and (max-width: 40em) {
   .p-datatable .p-datatable-thead > tr > th,
   .p-datatable .p-datatable-tfoot > tr > td {
      display: none !important;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-column-title-resp {
    min-width: 50%;
    display: inline-block;
    font-weight: bold;
  }

  .p-column-data-resp {
    min-width: 50%;
    display: inline-block;
  }

  .p-datatable .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid var(--surface-d);
  }
}
